<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ics_o_chevron_right">
      <path
        id="Vector"
        d="M6.9107 14.4101C6.58527 14.7355 6.58527 15.2632 6.9107 15.5886C7.23614 15.914 7.76378 15.914 8.08921 15.5886L13.0892 10.5886C13.4147 10.2632 13.4147 9.73553 13.0892 9.41009L8.08922 4.41009C7.76378 4.08466 7.23614 4.08466 6.9107 4.41009C6.58527 4.73553 6.58527 5.26317 6.9107 5.5886L11.3214 9.99935L6.9107 14.4101Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
import { Vue } from "vue-class-component";

export default class ChevronRightSmall extends Vue {}
</script>
